import React from "react";
import PortableText from "./portableText";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { format, distanceInWords, differenceInDays } from "date-fns";

import styles from "./blog-post.module.css";

function BlogPost(props) {
  const { _rawBody, title, mainImage, publishedAt } = props;

  const image = imageUrlFor(buildImageObj(mainImage))
    .width(1200)
    .height(Math.floor((9 / 16) * 1200))
    .fit("crop")
    .auto("format")
    .url();

  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div>
          <div style={{ backgroundImage: `url(${image})` }} className={styles.mainImage} />
        </div>
      )}

      <div className={styles.wrapper}>
        <h1 className={styles.title}>{title}</h1>

        {_rawBody && <PortableText blocks={_rawBody} />}

        <hr />

        <aside className={styles}>
          {publishedAt && (
            <div className={styles}>
              {differenceInDays(new Date(publishedAt), new Date()) > 3
                ? distanceInWords(new Date(publishedAt), new Date())
                : format(new Date(publishedAt), "MMMM Do, YYYY")}
            </div>
          )}
        </aside>
      </div>
    </article>
  );
}

export default BlogPost;
